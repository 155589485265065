<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-form-group>
            <label for="code">Tên thành viên<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="type_accident"
              rules="required"
              :custom-messages="code"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Giới tính</label>
            <div class="d-flex demo-inline-spacing">
              <b-form-radio
                v-model="dataInput.gender"
                :value="true"
                class="mt-1 mb-1"
              >
                Nam
              </b-form-radio>
              <b-form-radio
                v-model="dataInput.gender"
                :value="false"
                class="mt-1 mb-1"
              >
                Nữ
              </b-form-radio>
            </div>
          </b-form-group>
          <b-form-group>
            <label>Chức vụ</label>
            <b-form-input
              v-model="dataInput.position"
              type="text"
            />
          </b-form-group>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormRadio,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormRadio,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listEquipment: [],
      required,
      code: {
        required: 'Tên thanh tra là bắt buộc',
      },

      dataInput: {
        superviseId: '',
        name: '',
        gender: true,
        position: '',
      },

    }
  },
  methods: {
    accept(bvModalEvt) {
      this.dataInput.superviseId = this.$route.params.id
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    resetData() {
      this.dataInput = {
        superviseId: '',
        name: '',
        gender: true,
        position: '',
      }
    },

  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
