<template>
  <div>
    <h3 class="mt-1 mb-1">
      <feather-icon
        icon="InfoIcon"
        size="20"
        class="text-body"
      />
      Thông tin
    </h3>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >

        <b-form-group
          label-for="code"
        >
          <label for="code">Mã thanh tra<span class="text-danger">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="code"
            rules="required|max:10"
            :custom-messages="code"
          >
            <b-form-input
              v-model="dataInput.code"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Ngày thanh tra<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="dateTime"
            rules="required"
            :custom-messages="dateTime"
          >
            <date-time-picker
              v-model="dataInput.dateTime"
              :disabledInput="true"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>

          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Đợt thanh tra, kiểm tra<span class="required">(*)</span></label>
          <validation-provider
            #default="{ errors }"
            name="inspection"
            rules="required|max:255"
            :custom-messages="inspection"
          >
            <b-form-input
              v-model="dataInput.name"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          label-for="description"
        >
          <label for="description">Nội dung</label>
          <b-form-textarea
            v-model="dataInput.description"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="4"
        md="4"
      >
        <b-form-group
          label-for="description"
        >
          <label for="description">Trạng thái phúc tra</label>
          <b-form-input
            v-model="dataInput.response"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <h3 class="mt-1 mb-1">
      <feather-icon
        icon="PaperclipIcon"
        size="20"
        class="text-body"
      />
      File đính kèm
    </h3>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="File đính kèm"
          label-for="date"
        >
          <div class="d-flex">
            <b-form-input
              v-model="dataInput.fileName"
              :disabled="true"
            />

            <input
              ref="upload-file"
              type="file"
              class="d-none"
              @change="importFileExcel"
            />
            <b-button
              variant="outline-primary"
              class="btn-button-file ml-1"
              @click="uploadFile"
            >
              <feather-icon
                icon="UploadIcon"
                class="text-primary"
              />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
// import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
// import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
// import ConstantsApi from '@/views/occupational-safety-health/supervise/constants/ConstantsApi'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    DateTimePicker,
    ValidationProvider,
    BFormTextarea,
  },
  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      code: {
        required: 'Mã thanh tra là bắt buộc',
        max: 'Mã thanh tra không quá 10 ký tự',
      },
      dateTime: {
        required: 'Ngày thanh tra là bắt buộc',
      },
      inspection: {
        required: 'Đợt thanh tra, kiểm tra là bắt buộc',
        max: 'Đợt thanh tra, kiểm tra không quá 255 ký tự',
      },
      // listBussiness: [],
      userData: null,
    }
  },
  created() {
    // this.getListBussiness()
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },
    // async getListBussiness() {
    //   const { data } = await axiosApiInstance.get(ConstantsApi.GET_ALL_BUSSINESS)
    //   this.listBussiness = data?.data
    // },

  },
}
</script>
