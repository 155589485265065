<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      no-close-on-backdrop
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <validation-observer ref="rule">
        <b-form>
          <b-row>
            <b-col cols="12">

              <b-form-group
                label-for="code"
              >
                <label for="code">Số lần thanh tra</label>
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="numeric"
                  :custom-messages="code"
                >
                  <b-form-input
                    v-model="dataInput.count"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label-for="description"
              >
                <label for="description">Ngày dự kiến thanh tra<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="dateExpected"
                  rules="required"
                  :custom-messages="{ required: `Ngày dự kiến thanh tra là bắt buộc` }"
                >
                  <date-time-picker
                    v-model="dataInput.dateExpected"
                    :disabledInput="true"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label-for="description"
              >
                <label for="description">Doanh nghiệp<span class="required">(*)</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="Doanh nghiệp"
                  rules="required"
                  :custom-messages="{ required: `Doanh nghiệp là bắt buộc` }"
                >
                  <v-select
                    v-model="dataInput.bussinessId"
                    label="name"
                    :options="listBussiness || []"
                    :class="{'is-invalid':errors.length > 0 }"
                    :reduce="option => option.id"
                  >
                    <template v-slot:no-options="{ searching }">
                      <template v-if="searching">
                        Không có kết quả.
                      </template>
                      <em
                        v-else
                      >Không có dữ liệu</em>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="File đính kèm"
                label-for="date"
              >
                <div class="d-flex">
                  <b-form-input
                    v-model="dataInput.fileName"
                    :disabled="true"
                  />

                  <input
                    ref="upload-file"
                    type="file"
                    class="d-none"
                    @change="importFileExcel"
                  />
                  <b-button
                    variant="outline-primary"
                    class="btn-button-file ml-1"
                    @click="uploadFile"
                  >
                    <feather-icon
                      icon="UploadIcon"
                      class="text-primary"
                    />
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormRadio,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      listBussiness: [],
      required,
      code: {
        required: 'Tên thanh tra là bắt buộc',
      },

      dataInput: {
        superviseId: '',
        bussinessId: '',
        dateExpected: null,
        count: 0,
        fileName: '',
      },

    }
  },

  created() {
    this.getListBussiness()
  },

  methods: {
    accept(bvModalEvt) {
      this.dataInput.superviseId = this.$route.params.id
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },

    // Upload file tải lên
    uploadFile() {
      this.$refs['upload-file'].click()
    },

    // Import file cần thêm
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

    // Dánh sách tất cả doanh nghiệp
    async getListBussiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_ALL_BUSSINESS)
      this.listBussiness = data?.data
    },

    resetData() {
      this.dataInput = {
        superviseId: '',
        bussinessId: '',
        dateExpected: null,
        count: 0,
        fileName: '',
      }
    },

  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
