export default {
  // KHAI BÁO THIẾT BỊ
  API_SUPERVISE: 'Supervise/supervises',
  CREATE_SUPERVISE: 'Supervise/add-supervise',
  CREATE_SUPERVISE_DEPARTMENT: 'Supervise/add-supervise-educationDepartment',
  EDIT_SUPERVISE: 'Supervise/update-supervise',
  DELETE_SUPERVISE: 'Supervise/delete-supervise',
  DETAIL_SUPERVISE: 'Supervise/supervise/',
  DOWNLOAD_FILE: 'Supervise/download-supervise-bussiness-file',
  DOWNLOAD_FILE_SUPERVISE: 'Supervise/download-file',
  LIST_MISSION_OF_INSPECTION: 'Supervise/list-mission-of-inspection',
  CREATE_MISSION_OF_INSPECTION: 'Supervise/add-mission-of-inspection',
  DELETE_MISSION_OF_INSPECTION: 'Supervise/delete-mission-of-inspection',
  COMBOBOX: 'Equipment/get-combobox-equipment',
  GET_ALL_BUSSINESS: 'Bussiness/get-combobox-bussiness',
  LIST_SUPERVISE_BUSSINESS: 'Supervise/list-supervise-bussiness',
  CREATE_SUPERVISE_BUSSINESS: 'Supervise/add-supervise-bussiness',
  DELETE_SUPERVISE_BUSSINESS: 'Supervise/delete-supervise-bussiness',
}
