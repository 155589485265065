<template>
  <div>
    <my-tabs
      :listTabs="listTabs"
      defaultActive="activeTab"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="tabAccount">
        <info
          v-if="isRendered('info')"
          ref="info"
          :dataInput="dataInput"
          class="tab-pane"
          :class="{'active':activeTab==='info'}"
        />
        <list-equip
          v-if="isRendered('listEquip')"
          class="tab-pane"
          :class="{'active':activeTab==='listEquip'}"
        />
        <list-business
          v-if="isRendered('business')"
          class="tab-pane"
          :class="{'active':activeTab==='business'}"
        />
        <div class="d-flex mt-3">
          <b-button
            class="mr-1"
            variant="primary"
            @click="handleAddEdit"
          >
            Lưu lại
          </b-button>
          <b-button
            variant="outline-primary"
            type="reset"
            @click="back"
          >
            Quay lại
          </b-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MyTabs from '@/components/tabs/MyTabs.vue'
import Info from './components/Info.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '@/views/occupational-safety-health/supervise/constants/ConstantsApi'
import ListEquip from './components/ListSuper.vue'
import listBusiness from './components/ListBusiness.vue'

export default {
  components: {
    BButton,
    MyTabs,
    Info,
    ListEquip,
    listBusiness,
    ValidationObserver,
  },
  data() {
    return {
      activeTab: null,
      detailId: '',
      detailData: {},
      listTabs: [
        {
          key: 'info',
          title: 'Thông tin',
          icon: 'InfoIcon',
          isRendered: false,
        },
        {
          key: 'listEquip',
          title: 'Đoàn thanh tra',
          icon: 'UsersIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'business',
          title: 'Doanh nghiệp',
          icon: 'BriefcaseIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      dataInput: {
        code: '',
        dateTime: '',
        description: '',
        fileName: null,
        modelFormData: null,
        bussinessId: null,
        response: null,
      },
      userData: null,
    }
  },

  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },
    detailData(val) {
      if (val) {
        this.dataInput = {
          code: this.detailData.code,
          dateTime: this.detailData.dateTime,
          description: this.detailData.description,
          fileName: this.detailData.fileName,
          bussinessId: this.detailData.bussinessId,
          name: this.detailData.name,
          response: this.detailData.response,
        }
      }
    },
  },

  created() {
    if (this.$route.params.type) {
      this.activeTab = this.$route.params.type
      const tab = this.listTabs.find(x => x.key === this.activeTab)
      if (tab) tab.isRendered = true
    }
    if (this.activeTab === null) {
      this.activeTab = 'info'
      this.listTabs[0].isRendered = true
    }
    if (this.$route.params.id) {
      this.detailId = this.$route.params.id
      this.listTabs[1].isDisabled = false
      this.listTabs[2].isDisabled = false
      this.fetchDetailData(this.detailId)
    }
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  methods: {
    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    setActive(activeTab) {
      const active = this.activeTab
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      const indexTabNotActive = this.listTabs.findIndex(x => x.key === active)
      if (active !== this.activeTab) {
        if (tab) {
          tab.isRendered = true
          this.listTabs[indexTabNotActive].isRendered = false
        }
      }
    },
    back() {
      this.$router.push({ name: 'supervise-department' })
      this.dataInput = {
        Code: '',
        dateTime: '',
        description: '',
        fileName: null,
        modelFormData: null,
      }
    },
    async fetchDetailData(id) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_SUPERVISE}${id}`)
      this.detailData = data
    },

    handleAddEdit(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.tabAccount.validate().then(success => {
        if (success) {
          if (this.$route.params.id) {
            this.handleEdit()
          } else {
            this.handleAdd()
          }
        }
      })
    },

    // check string null
    removeDataEmpty(value) {
      return ![undefined, null, ''].includes(value) ? value : ''
    },

    // Form input
    async handleAdd() {
      const formData = new FormData()
      if (this.dataInput.modelFormData) {
        formData.append('Code', this.dataInput.code)
        formData.append('Name', this.dataInput.name)
        formData.append('Response', this.dataInput.response)
        formData.append('DateTime', this.dataInput.dateTime)
        formData.append('Description', this.removeDataEmpty(this.dataInput.description))
        formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
        formData.append('formFile', this.dataInput.modelFormData.files)
        formData.append('FileName', this.dataInput.fileName)
      } else {
        formData.append('Code', this.dataInput.code)
        formData.append('Name', this.dataInput.name)
        formData.append('DateTime', this.dataInput.dateTime)
        formData.append('Response', this.dataInput.response)
        formData.append('Description', this.removeDataEmpty(this.dataInput.description))
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.post(ConstantsApi.CREATE_SUPERVISE_DEPARTMENT, formData).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thêm thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'supervise-edit', params: { id: response?.data?.data.id } })
        this.listTabs[1].isDisabled = false
        this.listTabs[2].isDisabled = false
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    async handleEdit() {
      const formData = new FormData()
      if (this.dataInput.modelFormData) {
        formData.append('Id', this.$route.params.id)
        formData.append('Name', this.dataInput.name)
        formData.append('Code', this.dataInput.code)
        formData.append('DateTime', this.dataInput.dateTime)
        formData.append('Response', this.dataInput.response)
        formData.append('Description', this.removeDataEmpty(this.dataInput.description))
        formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
        formData.append('formFile', this.dataInput.modelFormData.files)
        formData.append('FileName', this.dataInput.fileName)
      } else {
        formData.append('Id', this.$route.params.id)
        formData.append('Name', this.dataInput.name)
        formData.append('Code', this.dataInput.code)
        formData.append('Response', this.dataInput.response)
        formData.append('DateTime', this.dataInput.dateTime)
        formData.append('Description', this.removeDataEmpty(this.dataInput.description))
      }
      this.$showAllPageLoading()
      const res = await axiosApiInstance.put(ConstantsApi.EDIT_SUPERVISE, formData).then(response => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cập nhật thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'supervise-department' })
        this.listTabs[1].isDisabled = false
        this.listTabs[2].isDisabled = false
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>
