<template>
  <div>
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm doanh nghiệp'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @importFile="() => {}"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field == 'code'">
          <b-badge variant="light-primary">
            {{ props.row.code }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'dateExpected'">
          <span>
            {{ props.row.dateExpected | formatDateToDDMM }}
          </span>
        </span>

        <!-- Chức năng -->
        <span v-else-if="props.column.field == 'action'">
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />

          <feather-icon
            v-if="props.row.filePath"
            icon="DownloadIcon"
            size="18"
            class="text-body ml-2"
            @click="downloadFilePdf(props.row.id, props.row.fileName)"
          />
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
    <!-- thêm mới modal  -->
    <modal-create-business
      :id="modalCreateBusiness"
      title="Thêm doanh nghiệp"
      @accept="handleModal"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import ModalCreateBusiness from './ModalCreateBusiness.vue'

// import EditEquip from './components/EditEquip.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    ConfirmModal,
    BBadge,
    ModalCreateBusiness,
    // EditEquip,
  },

  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      totalRecord: 0,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      showBtnMultiDelete: false,
      deleteIds: [],
      isActive: false,
      modalCreateBusiness: 'modal-id-create-business',
      dataList: [],
      columns: [
        {
          label: 'Tên doanh nghiệp',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'Tên file',
          field: 'fileName',
          sortable: false,
        },
        {
          label: 'Ngày dự kiến thanh tra',
          field: 'dateExpected',
          sortable: false,
        },
        {
          label: 'Số lần thanh tra',
          field: 'count',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        superviseId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
    }
  },

  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {
    // check string null
    removeDataEmpty(value) {
      return ![undefined, null, ''].includes(value) ? value : ''
    },

    async handleModal(param) {
      const formData = new FormData()
      if (param.modelFormData) {
        formData.append('SuperviseId', param.superviseId)
        formData.append('Count', param.count)
        formData.append('DateExpected', param.dateExpected)
        formData.append('IsSecure', param.modelFormData.isSecure)
        formData.append('formFile', param.modelFormData.files)
        formData.append('FileName', param.fileName)
        formData.append('BussinessId', param.bussinessId ? param.bussinessId : this.removeDataEmpty(param.bussinessId))
      } else {
        formData.append('SuperviseId', param.superviseId)
        formData.append('Count', param.count)
        formData.append('DateExpected', param.dateExpected)
        formData.append('BussinessId', param.bussinessId ? param.bussinessId : this.removeDataEmpty(param.bussinessId))
      }
      await axiosApiInstance.post(ConstantsApi.CREATE_SUPERVISE_BUSSINESS, formData).then(response => {
        if (response.status === 200) {
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalCreateBusiness)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // Chuyển trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchData(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        superviseId: this.$route.params.id,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUPERVISE_BUSSINESS, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.superviseId = this.$route.params.id
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUPERVISE_BUSSINESS, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Hiển thị modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalCreateBusiness)
    },
    search(val) {
      if (val != null) {
        this.urlQuery.declareEquipmentId = this.$route.params.id
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.declareEquipmentId = this.$route.params.id
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },

    // tải file đính kèm.
    downloadFilePdf(id, fileName) {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_FILE, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName) // or any other extension
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.$root.$bvToast.toast('Không có file', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
